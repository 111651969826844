// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#5865F2',
        secondary: '#57F287',
        accent: '#EB459E',
      },
      dark: {
        primary: '#5865F2',
        secondary: '#57F287',
        accent: '#EB459E',
      },
    },
  },
})
